import React from "react";
// import "./NursenseMedia.css";

const NursenseMedia: React.FC = () => {
  return (
    <>
      {/* <div className="mediaContainer">
        <div className="mediaWrapper">
          <div className="mediaTitleWrapper">
            <h1>NURSENSE MEDIA</h1>
            <p>언론 속의 NURSENSE</p>
          </div>
          <div className="mediaCardWrapper"></div>
        </div>
      </div> */}
    </>
  );
};

export default NursenseMedia;
