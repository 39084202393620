import React from "react";
import OpeningContentSlide from "../../components/OpeningContentSlide";

const OpeningCourse: React.FC = () => {
  return (
    <>
      <OpeningContentSlide />
    </>
  );
};

export default OpeningCourse;
